var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-money" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit && !_vm.spinners.loading
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "Vouchers" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          !_vm.spinners.loading
            ? _c(
                "CForm",
                { staticClass: "voucher-form" },
                [
                  _c(
                    "CTabs",
                    {
                      staticClass: "tab-menu",
                      attrs: {
                        "active-tab": _vm.activeTab,
                        variant: "pills",
                        vertical: {
                          navs: "col-xl-2 col-md-3",
                          content: "col-xl-10 col-md-9",
                        },
                      },
                      on: {
                        "update:activeTab": function ($event) {
                          _vm.activeTab = $event
                        },
                        "update:active-tab": function ($event) {
                          _vm.activeTab = $event
                        },
                      },
                    },
                    [
                      _c(
                        "CTab",
                        { attrs: { active: "" } },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("CIcon", { attrs: { name: "cil-task" } }),
                              _vm._v(" Basics "),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "Code:",
                                    placeholder: "Code",
                                    lazy: false,
                                    value: _vm.$v.form.code.$model,
                                    isValid: _vm.checkIfValid("code"),
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.code,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Name:",
                                    placeholder: "Name",
                                    lazy: false,
                                    value: _vm.$v.form.name.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.name,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c("CTextarea", {
                                  staticClass: "w-100",
                                  attrs: {
                                    rows: "3",
                                    label: "Description:",
                                    placeholder: "Description",
                                    value: _vm.$v.form.description.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.description,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex w-100 mr-2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group mr-3" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "d-block",
                                            staticStyle: {
                                              "font-weight": "500",
                                            },
                                          },
                                          [_vm._v("Is fixed:")]
                                        ),
                                        _c("CSwitch", {
                                          attrs: {
                                            checked:
                                              _vm.$v.form.is_fixed.$model,
                                            labelOn: "YES",
                                            labelOff: "NO",
                                            color: "success",
                                            shape: "pill",
                                            size: "lg",
                                          },
                                          on: {
                                            "update:checked": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.$v.form.is_fixed,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("CInput", {
                                      staticClass: "w-100",
                                      attrs: {
                                        label: "Discount amount:",
                                        placeholder: "0.00",
                                        value:
                                          _vm.$v.form.discount_amount.$model,
                                        type: "number",
                                        step: "0.01",
                                        pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.discount_amount,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          _vm.form.is_fixed === true
                                            ? {
                                                key: "prepend-content",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "h4",
                                                      { staticClass: "mb-0" },
                                                      [_vm._v("€")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : {
                                                key: "append-content",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "h4",
                                                      { staticClass: "mb-0" },
                                                      [_vm._v("%")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Min. value:",
                                    placeholder: "0.00",
                                    value: _vm.$v.form.minvalue.$model,
                                    type: "number",
                                    step: "0.01",
                                    pattern: "^\\d+(?:\\.\\d{1,2})?$",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.minvalue,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-content",
                                        fn: function () {
                                          return [
                                            _c("h4", { staticClass: "mb-0" }, [
                                              _vm._v("€"),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1701165074
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    type: "date",
                                    label: "Start:",
                                    min: new Date().toISOString().slice(0, 10),
                                    value: _vm.$v.form.starts_at.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.starts_at,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    type: "date",
                                    label: "Expire:",
                                    min: new Date().toISOString().slice(0, 10),
                                    value: _vm.$v.form.expires_at.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.expires_at,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    type: "number",
                                    label: "Max. usage:",
                                    placeholder: "0",
                                    value: _vm.$v.form.max_uses.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.max_uses,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    type: "number",
                                    label: "Max. usage per user:",
                                    placeholder: "0",
                                    value: _vm.$v.form.max_uses_user.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.max_uses_user,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group w-100 mr-2" },
                                  [
                                    _c("label", { staticClass: "d-block" }, [
                                      _vm._v("User:"),
                                    ]),
                                    _c(
                                      "v-select",
                                      {
                                        attrs: {
                                          placeholder:
                                            _vm.users.length == 0
                                              ? "Search for a user.."
                                              : "Select or search for a user..",
                                          options: _vm.users,
                                          reduce: (c) => c.id,
                                          searchable: true,
                                          loading:
                                            _vm.spinners.searching &&
                                            _vm.users.length == 0,
                                        },
                                        on: { search: _vm.searchUsers },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "option",
                                              fn: function (user) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "c-avatar mr-2",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "c-avatar-img",
                                                            attrs: {
                                                              src:
                                                                user.image_icon ||
                                                                user.gravatar,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "small",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                user.first_name
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " #" +
                                                              _vm._s(user.id) +
                                                              " (" +
                                                              _vm._s(
                                                                user.email
                                                              ) +
                                                              ")"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "selected-option",
                                              fn: function (user) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(user.first_name) +
                                                      " #" +
                                                      _vm._s(user.id) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1414154059
                                        ),
                                        model: {
                                          value: _vm.$v.form.user_id.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.form.user_id,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.form.user_id.$model",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "no-options" },
                                            slot: "no-options",
                                          },
                                          [_vm._v("Type to search users..")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    type: "number",
                                    label: "Valid until the user:",
                                    placeholder: "0",
                                    value: _vm.$v.form.untill_user_id.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.untill_user_id,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group w-100" },
                                  [
                                    _c("label", { staticClass: "d-block" }, [
                                      _vm._v("Restaurant:"),
                                    ]),
                                    _c("v-select", {
                                      attrs: {
                                        reduce: (c) => c.value,
                                        placeholder: "Please select..",
                                        searchable: true,
                                        options: _vm.restaurants,
                                      },
                                      model: {
                                        value: _vm.$v.form.resto_id.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.resto_id,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression: "$v.form.resto_id.$model",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("CSpinner", { attrs: { color: "primary" } }),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky-bottom" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap align-items-center",
            staticStyle: { gap: "0.75rem" },
          },
          [
            _c(
              "CButton",
              {
                attrs: {
                  color: "primary",
                  disabled: _vm.spinners.loading || _vm.spinners.btnSubmit,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm.spinners.btnSubmit
                  ? _c("CSpinner", { attrs: { size: "sm" } })
                  : _vm._e(),
                _vm._v(" Submit "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.spinners.btnSubmit,
            expression: "spinners.btnSubmit",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }